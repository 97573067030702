import React from "react";
import { Divider } from "../../components/Divider";
import FoundationPage from "../../components/FoundationPage";
import Layout from "../../components/Layout";

export default function DesignTokensPage({ location }) {
  let colors = [
    ["white", "#FFFFFF"],
    ["gray-0", "#FAFAFA"],
    ["gray-100", "#F1F2F3"],
    ["gray-200", "#DDDEE4"],
    ["gray-300", "#BABDC9"],
    ["gray-400", "#989DAE"],
    ["gray-500", "#787D91"],
    ["gray-600", "#65697B"],
    ["gray-700", "#484C5B"],
    ["gray-800", "#363945"],
    ["gray-900", "#22252F"],
    ["gray-1000", "#181921"],
    ["blurple-0", "#FBFAFF"],
    ["blurple-100", "#F1F0FE"],
    ["blurple-200", "#DCD9FD"],
    ["blurple-300", "#C2BCFB"],
    ["blurple-400", "#958CF8"],
    ["blurple-500", "#6A46FE"],
    ["blurple-600", "#4B31AF"],
    ["blurple-700", "#332277"],
    ["blurple-800", "#291B5F"],
    ["blurple-900", "#190F43"],
    ["blurple-1000", "#130B32"],
    ["aqua-0", "#F7FDFD"],
    ["aqua-100", "#EBF9F9"],
    ["aqua-200", "#D0F1F1"],
    ["aqua-300", "#A0E3E3"],
    ["aqua-400", "#72D5D5"],
    ["aqua-500", "#32BAB9"],
    ["aqua-600", "#03A0A0"],
    ["aqua-700", "#027878"],
    ["aqua-800", "#005252"],
    ["aqua-900", "#013C3C"],
    ["aqua-1000", "#002424"],
    ["eggplant-0", "#FEF6FD"],
    ["eggplant-100", "#FCEDFA"],
    ["eggplant-200", "#F4CDED"],
    ["eggplant-300", "#E99BDC"],
    ["eggplant-400", "#DE68CA"],
    ["eggplant-500", "#BC1AA1"],
    ["eggplant-600", "#9D0784"],
    ["eggplant-700", "#760563"],
    ["eggplant-800", "#610552"],
    ["eggplant-900", "#4E0441"],
    ["eggplant-1000", "#300328"],
    ["blue-0", "#F5FAFE"],
    ["blue-100", "#E2F1FD"],
    ["blue-200", "#C5E4FB"],
    ["blue-300", "#8CC9F8"],
    ["blue-400", "#54AEF3"],
    ["blue-500", "#1E95EF"],
    ["blue-600", "#0D7DD3"],
    ["blue-700", "#0B67AD"],
    ["blue-800", "#095086"],
    ["blue-900", "#063960"],
    ["blue-1000", "#002142"],
    ["green-0", "#F6FEFA"],
    ["green-100", "#E5FBF0"],
    ["green-200", "#C9F8E2"],
    ["green-300", "#87E8BB"],
    ["green-400", "#4BD293"],
    ["green-500", "#24BC75"],
    ["green-600", "#29A36A"],
    ["green-700", "#218355"],
    ["green-800", "#186240"],
    ["green-900", "#054D2B"],
    ["green-1000", "#03301B"],
    ["red-0", "#FFF5F7"],
    ["red-100", "#FDE2E6"],
    ["red-200", "#FBBCC4"],
    ["red-300", "#F28E9D"],
    ["red-400", "#ED5A70"],
    ["red-500", "#D30D4C"],
    ["red-600", "#AD0B3E"],
    ["red-700", "#860931"],
    ["red-800", "#600623"],
    ["red-900", "#4D051C"],
    ["red-1000", "#300311"],
    ["orange-0", "#FFF8F0"],
    ["orange-100", "#FFF1E1"],
    ["orange-200", "#FEE2C3"],
    ["orange-300", "#FAC689"],
    ["orange-400", "#F8A94F"],
    ["orange-500", "#FA8C0F"],
    ["orange-600", "#D77709"],
    ["orange-700", "#A05703"],
    ["orange-800", "#764105"],
    ["orange-900", "#4D2B05"],
    ["orange-1000", "#301B03"],
    ["yellow-0", "#FFFCF0"],
    ["yellow-100", "#FFFAE0"],
    ["yellow-200", "#FFF5C2"],
    ["yellow-300", "#FFEB85"],
    ["yellow-400", "#FFE047"],
    ["yellow-500", "#FFD500"],
    ["yellow-600", "#E0BB00"],
    ["yellow-700", "#B89F00"],
    ["yellow-800", "#7A6A00"],
    ["yellow-900", "#524700"],
    ["yellow-1000", "#292300"],
  ];

  return (
    <Layout location={location}>
      <FoundationPage
        pageTitle="Design Tokens"
        description="Design tokens are an abstract, human-centered way to store variables. We use tokens instead of hard-coded values because they provide consistency and are easier for humans to reference. "
        link1title="View in Figma"
        link1url="https://www.figma.com/file/08RyCY34fSWYCxwrFWbojr/GDS-%2F-Core-Library?node-id=12154%3A565169"
        link2title="View MAF Storybook"
        link2url="https://webcomponents.maf.nielsenmedia.com/gds/?path=/docs/getting-started-design-tokens--page"
        tocItems={["Colors", "Typography", "Spacing", "UI"]}
      >
        <React.Fragment>
          <div className="section" id="colors">
            <h2>Color Tokens</h2>
            <table class="styled-table">
              <tr>
                <th>Color</th>
                <th>Value</th>
                <th className="last">Token</th>
                {/* <th>Web Components Variable</th>
                <th className="last">MAF Variable</th> */}
              </tr>
              {colors.map((color) => (
                <tr>
                  <td style={{ width: "300px" }}>
                    <div
                      style={{
                        backgroundColor: "var(--gds-" + color[0],
                        borderRadius: "var(--gds-radii-400",
                        width: "var(--gds-space--800)",
                        height: "var(--gds-space-600)",
                      }}
                      id={color}
                    ></div>
                  </td>

                  <td>{color[1]}</td>
                  <td className="callout">{color[0]}</td>
                  {/* <td>Web Components Variable</td>
                  <td>MAF Variable</td> */}
                </tr>
              ))}
            </table>
          </div>
          <Divider />
          <div className="section" id="typography">
            <h2>Typography Tokens</h2>
            <div
              className="styled-grid"
              style={{ gridTemplateColumns: "1fr 1fr" }}
            >
              <div className="type-section">
                <h3>Font Family</h3>
                <table class="styled-table">
                  <tr>
                    <th>Token</th>
                    <th className="last">Font Size</th>
                  </tr>
                  <tr>
                    <td className="callout">font-family</td>
                    <td>"Inter", "Noto Sans", sans-serif</td>
                  </tr>
                </table>
                <div className="type-section mt-space-900">
                  <h3>Font Weight</h3>
                  <p3
                    className="gds-size-100-regular mt-space-200"
                    style={{ color: "var(--gds-gray-600" }}
                  >
                    *Note:* In Figma this is Medium weight. It's sized down to
                    400 on the web because of browser antialiasing.
                  </p3>
                  <table class="styled-table mt-space-300">
                    <tr>
                      <th>Token</th>
                      <th className="last">Font Weight</th>
                    </tr>
                    <tr>
                      <td className="callout">weight-100*</td>
                      <td>400</td>
                    </tr>
                    <tr>
                      <td className="callout">weight-200</td>
                      <td>600</td>
                    </tr>
                    <tr>
                      <td className="callout">weight-300</td>
                      <td>700</td>
                    </tr>
                  </table>
                </div>
              </div>
              <div className="type-section">
                <h3>Font Size</h3>
                <table class="styled-table">
                  <tr>
                    <th>Token</th>
                    <th className="last">Value</th>
                  </tr>
                  <tr>
                    <td className="callout">size-0</td>
                    <td>11px</td>
                  </tr>
                  <tr>
                    <td className="callout">size-100</td>
                    <td>12px</td>
                  </tr>
                  <tr>
                    <td className="callout">size-200</td>
                    <td>13px</td>
                  </tr>
                  <tr>
                    <td className="callout">size-300</td>
                    <td>14px</td>
                  </tr>
                  <tr>
                    <td className="callout">size-400</td>
                    <td>15px</td>
                  </tr>
                  <tr>
                    <td className="callout">size-500</td>
                    <td>16px</td>
                  </tr>
                  <tr>
                    <td className="callout">size-600</td>
                    <td>18px</td>
                  </tr>
                  <tr>
                    <td className="callout">size-700</td>
                    <td>20px</td>
                  </tr>
                  <tr>
                    <td className="callout">size-800</td>
                    <td>24px</td>
                  </tr>
                  <tr>
                    <td className="callout">size-900</td>
                    <td>32px</td>
                  </tr>
                  <tr>
                    <td className="callout">size-1000</td>
                    <td>48px</td>
                  </tr>
                </table>
              </div>
              <div className="type-section">
                <h3>Line Height</h3>
                <table class="styled-table">
                  <tr>
                    <th>Token</th>
                    <th className="last">Line Height</th>
                  </tr>
                  <tr>
                    <td className="callout">lh-0</td>
                    <td>1</td>
                  </tr>
                  <tr>
                    <td className="callout">lh-100</td>
                    <td>12px</td>
                  </tr>
                  <tr>
                    <td className="callout">lh-200</td>
                    <td>16px</td>
                  </tr>
                  <tr>
                    <td className="callout">lh-300</td>
                    <td>20px</td>
                  </tr>
                  <tr>
                    <td className="callout">lh-400</td>
                    <td>24px</td>
                  </tr>
                  <tr>
                    <td className="callout">lh-500</td>
                    <td>28px</td>
                  </tr>
                  <tr>
                    <td className="callout">lh-600</td>
                    <td>32px</td>
                  </tr>
                  <tr>
                    <td className="callout">lh-700</td>
                    <td>48px</td>
                  </tr>
                  <tr>
                    <td className="callout">lh-800</td>
                    <td>56px</td>
                  </tr>
                </table>
              </div>
              <div className="type-section">
                <h3>Letter Spacing</h3>
                <table class="styled-table">
                  <tr>
                    <th>Token</th>
                    <th className="last">Value</th>
                  </tr>
                  <tr>
                    <td className="callout">ls-0</td>
                    <td>calc(11 * -0.01)px</td>
                  </tr>
                  <tr>
                    <td className="callout">ls-100</td>
                    <td>calc(12 * -0.01)px</td>
                  </tr>
                  <tr>
                    <td className="callout">ls-200</td>
                    <td>calc(13 * -0.01)px</td>
                  </tr>
                  <tr>
                    <td className="callout">ls-300</td>
                    <td>calc(14 * -0.015)px</td>
                  </tr>
                  <tr>
                    <td className="callout">ls-400</td>
                    <td>calc(15 * -0.015)px</td>
                  </tr>
                  <tr>
                    <td className="callout">ls-500</td>
                    <td>calc(16 * -0.015)px</td>
                  </tr>
                  <tr>
                    <td className="callout">ls-600</td>
                    <td>calc(18 * -0.015)px</td>
                  </tr>
                  <tr>
                    <td className="callout">ls-700</td>
                    <td>calc(20 * -0.015)px</td>
                  </tr>
                  <tr>
                    <td className="callout">ls-800</td>
                    <td>calc(24 * -0.02)px</td>
                  </tr>
                  <tr>
                    <td className="callout">ls-900</td>
                    <td>calc(32 * -0.02)px</td>
                  </tr>
                  <tr>
                    <td className="callout">ls-1000</td>
                    <td>calc(48 * -0.02)px</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <Divider />

          <div className="section" id="spacing">
            <h2>Spacing Tokens</h2>
            <div
              className="styled-grid"
              style={{ gridTemplateColumns: "1fr 1fr" }}
            >
              <div className="type-section">
                <h3>Spacing</h3>
                <table class="styled-table">
                  <tr>
                    <th>Token</th>
                    <th>Value (pixels)</th>
                    <th className="last">Value (rems)</th>
                  </tr>
                  <tr>
                    <td className="callout">space-0</td>
                    <td>0px</td>
                    <td>0rem</td>
                  </tr>
                  <tr>
                    <td className="callout">space-100</td>
                    <td>2px</td>
                    <td>0.125rem</td>
                  </tr>
                  <tr>
                    <td className="callout">space-200</td>
                    <td>4px</td>
                    <td>0.25rem</td>
                  </tr>
                  <tr>
                    <td className="callout">space-300</td>
                    <td>8px</td>
                    <td>0.5rem</td>
                  </tr>
                  <tr>
                    <td className="callout">space-400</td>
                    <td>12px</td>
                    <td>0.75rem</td>
                  </tr>
                  <tr>
                    <td className="callout">space-500</td>
                    <td>16px</td>
                    <td>1rem</td>
                  </tr>
                  <tr>
                    <td className="callout">space-600</td>
                    <td>20px</td>
                    <td>1.25rem</td>
                  </tr>
                  <tr>
                    <td className="callout">space-700</td>
                    <td>24px</td>
                    <td>1.5rem</td>
                  </tr>
                  <tr>
                    <td className="callout">space-800</td>
                    <td>32px</td>
                    <td>2rem</td>
                  </tr>
                  <tr>
                    <td className="callout">space-900</td>
                    <td>48px</td>
                    <td>3rem</td>
                  </tr>
                  <tr>
                    <td className="callout">space-1000</td>
                    <td>64px</td>
                    <td>4rem</td>
                  </tr>
                </table>
              </div>
              <div className="type-section">
                <h3>Layout</h3>
                <table class="styled-table">
                  <tr>
                    <th>Token</th>
                    <th>Value (pixels)</th>
                    <th className="last">Value (rems)</th>
                  </tr>
                  <tr>
                    <td className="callout">layout-0</td>
                    <td>192px</td>
                    <td>12rem</td>
                  </tr>
                  <tr>
                    <td className="callout">layout-100</td>
                    <td>224px</td>
                    <td>14rem</td>
                  </tr>
                  <tr>
                    <td className="callout">layout-200</td>
                    <td>256px</td>
                    <td>16rem</td>
                  </tr>
                  <tr>
                    <td className="callout">layout-300</td>
                    <td>320px</td>
                    <td>20rem</td>
                  </tr>
                  <tr>
                    <td className="callout">layout-400</td>
                    <td>384px</td>
                    <td>24rem</td>
                  </tr>
                  <tr>
                    <td className="callout">layout-500</td>
                    <td>512px</td>
                    <td>32rem</td>
                  </tr>
                  <tr>
                    <td className="callout">layout-600</td>
                    <td>768px</td>
                    <td>48rem</td>
                  </tr>
                  <tr>
                    <td className="callout">layout-700</td>
                    <td>1024px</td>
                    <td>64rem</td>
                  </tr>
                  <tr>
                    <td className="callout">layout-800</td>
                    <td>1280px</td>
                    <td>80rem</td>
                  </tr>
                  <tr>
                    <td className="callout">layout-900</td>
                    <td>1600px</td>
                    <td>100rem</td>
                  </tr>
                  <tr>
                    <td className="callout">layout-1000</td>
                    <td>1920px</td>
                    <td>120rem</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <Divider />

          <div className="section" id="ui">
            <h2>UI Tokens</h2>
            <div
              className="styled-grid"
              style={{ gridTemplateColumns: "1fr 1fr" }}
            >
              <div className="type-section">
                <h3>Borders</h3>
                <table class="styled-table">
                  <tr>
                    <th>Token</th>
                    <th className="last">Value</th>
                  </tr>
                  <tr>
                    <td className="callout">border-0</td>
                    <td>0px</td>
                  </tr>
                  <tr>
                    <td className="callout">border-100</td>
                    <td>2px</td>
                  </tr>
                  <tr>
                    <td className="callout">border-200</td>
                    <td>4px</td>
                  </tr>
                </table>
                <div className="type-section mt-space-600">
                  <h3>Radii</h3>
                  <table class="styled-table">
                    <tr>
                      <th>Token</th>
                      <th className="last">Value</th>
                    </tr>
                    <tr>
                      <td className="callout">radii-0</td>
                      <td>0px</td>
                    </tr>
                    <tr>
                      <td className="callout">radii-100</td>
                      <td>4px</td>
                    </tr>
                    <tr>
                      <td className="callout">radii-200</td>
                      <td>6px</td>
                    </tr>
                    <tr>
                      <td className="callout">radii-300</td>
                      <td>8px</td>
                    </tr>
                    <tr>
                      <td className="callout">radii-400</td>
                      <td>12px</td>
                    </tr>
                    <tr>
                      <td className="callout">radii-500</td>
                      <td>16px</td>
                    </tr>
                    <tr>
                      <td className="callout">radii-600</td>
                      <td>32px</td>
                    </tr>
                  </table>
                </div>
              </div>
              <div className="type-section">
                <h3>Elevation</h3>
                <table class="styled-table">
                  <tr>
                    <th>Token</th>
                    <th className="last">Value</th>
                  </tr>
                  <tr>
                    <td className="callout">elevation-absolute-bottom</td>
                    <td>-999</td>
                  </tr>
                  <tr>
                    <td className="callout">elevation-absolute-top</td>
                    <td>999</td>
                  </tr>
                  <tr>
                    <td className="callout">elevation-0</td>
                    <td>0</td>
                  </tr>
                  <tr>
                    <td className="callout">elevation-100</td>
                    <td>100</td>
                  </tr>
                  <tr>
                    <td className="callout">elevation-150</td>
                    <td>150</td>
                  </tr>
                  <tr>
                    <td className="callout">elevation-200</td>
                    <td>200</td>
                  </tr>
                  <tr>
                    <td className="callout">elevation-250</td>
                    <td>250</td>
                  </tr>
                  <tr>
                    <td className="callout">elevation-300</td>
                    <td>300</td>
                  </tr>
                  <tr>
                    <td className="callout">elevation-350</td>
                    <td>350</td>
                  </tr>
                  <tr>
                    <td className="callout">elevation-400</td>
                    <td>400</td>
                  </tr>
                  <tr>
                    <td className="callout">elevation-450</td>
                    <td>450</td>
                  </tr>
                </table>
              </div>
            </div>
            <div className="type-section mt-space-800">
              <h3>Shadows</h3>
              <table class="styled-table ">
                <tr>
                  <th>Token</th>
                  <th className="last">Value</th>
                </tr>
                <tr>
                  <td className="callout">shadow-0</td>
                  <td>0 0 0 rgba(17, 18, 24, 0)</td>
                </tr>
                <tr>
                  <td className="callout">shadow-100</td>
                  <td>0 0 12px rgba(17, 18, 24, 0.08)</td>
                </tr>
                <tr>
                  <td className="callout">shadow-200</td>
                  <td>0 0 8px rgba(17, 18, 24, 0.16)</td>
                </tr>
                <tr>
                  <td className="callout">shadow-300</td>
                  <td>0 0 12px 2px rgba(17, 18, 24, 0.20)</td>
                </tr>
                <tr>
                  <td className="callout">shadow-400</td>
                  <td>0 0 8px 2px rgba(17, 18, 24, 0.64)</td>
                </tr>
                <tr>
                  <td className="callout">shadow-500</td>
                  <td>0 0 12px 4px rgba(17, 18, 24, 0.80)</td>
                </tr>
                <tr>
                  <td className="callout">shadow-600</td>
                  <td>0 0 16px 12px rgba(17, 18, 24, 0.96)</td>
                </tr>
                <tr>
                  <td className="callout">shadow-active-light-primary</td>
                  <td>0px 0px 0px 6px rgba(149, 140, 248, 0.16)</td>
                </tr>
                <tr>
                  <td className="callout">shadow-active-light-neutral</td>
                  <td>0px 0px 0px 6px rgba(120, 125, 145, 0.16)</td>
                </tr>
                <tr>
                  <td className="callout">shadow-active-light-danger</td>
                  <td>0px 0px 0px 6px rgba(230, 59, 49, 0.24)</td>
                </tr>
                <tr>
                  <td className="callout">shadow-active-dark-primary</td>
                  <td>0px 0px 0px 6px rgba(99, 70, 254, 0.16)</td>
                </tr>
                <tr>
                  <td className="callout">shadow-active-dark-neutral</td>
                  <td>0px 0px 0px 6px rgba(120, 125, 145, 0.32)</td>
                </tr>
                <tr>
                  <td className="callout">shadow-active-dark-danger</td>
                  <td>0px 0px 0px 6px rgba(230, 59, 49, 0.16)</td>
                </tr>
              </table>
            </div>
          </div>
          <Divider />
        </React.Fragment>
      </FoundationPage>
    </Layout>
  );
}
